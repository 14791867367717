import ApiService from "./api.service";

const serviceURL = 'user'
const UserService = {
    /**
	 * Acquire User by user ID
	 * @param {Integer} userId
	 * @returns {Object} project details
	 */
    get(userId){
        return ApiService.get(`${serviceURL}`,`${userId}`)
    },
    update(userId, contents) {
        let body = {
            doc_content: contents,
        };
        return ApiService.put(`${serviceURL}/${userId}`, body);
    }
}

export default UserService;