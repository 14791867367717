import ApiService from './api.service';

const serviceURL = 'creative';
const CreativeService = {
	/**
	 * Acquire decision and children by projectID
	 * @param {Integer} projectId
	 * @returns {Object} decision and children
	 */
	get(projectId) {
		return ApiService.get(`${serviceURL}`, `${projectId}/children?withParent=true`);
	},

	/**
	 * Create new Creative record
	 * @param {Object} body {doc_content: {schedule Object}}
	 * @returns {Object} include success message and record ID
	 */
	post(body) {
		return ApiService.post(`${serviceURL}/`, body);
	},
	put(id, body) {
		return ApiService.put(`${serviceURL}/${id}`, body);
	},
};

export default CreativeService;
